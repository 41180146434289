import 'whatwg-fetch';
import 'utils/object.isInfinite.polyfill.js';

import React, { Component } from 'react';
import _get from 'lodash/get';
import Layout from 'components/layout'
import Hero from 'components/Layout/Hero';
import Bottom from 'components/Bottom/Bottom';
import GetStarted from 'components/GetStarted';
import TermsContainer from 'components/terms/TermsContainer';

class Terms extends Component {
  render () {
    return (
      <Layout page="terms" lang={_get(this.props, 'pageContext.lang', 'en')}>
        <Hero />
        <div>
          <TermsContainer />
          <GetStarted />
          <Bottom />
        </div>
      </Layout>
    );
  }
}

export default Terms;

import React, { Component } from 'react';
import { FormattedMessage as L } from 'react-intl';
import Link from 'components/Link';
import WL from 'constants/whiteLabel';
import globalStyles from 'pages/pages.module.css';
import s from './TermsContainer.module.css';

class TermsContainer extends Component {
  render() {
    return (
      <div className={s.root}>
        <h1><L id="terms.title" defaultMessage="Hiveon Terms and Conditions" /></h1>
        <p><i><L id="terms.effective" defaultMessage="Effective from October 16, 2017" /></i></p>
        <h2><L id="terms.introduction.title" defaultMessage="Introduction" /></h2>
        <p><L id="terms.introduction.text1" defaultMessage="Welcome to Hiveon!" /></p>
        <p>
          <L
            id="terms.introduction.text2"
            defaultMessage="These terms and conditions outline the rules and regulations for the use of {hiveosHref}, {forumHref}, {hiveonHref} websites (the 'Services')."
            values={{
              hiveosHref: <a href="https://hiveos.farm/" className={globalStyles.link}>https://hiveos.farm</a>,
              forumHref: <Link external noRef href="https://forum.hiveos.farm/" className={globalStyles.link}>https://forum.hiveos.farm</Link>,
              hiveonHref: <Link external noRef href="https://www.hiveon.net/" className={globalStyles.link}>https://www.hiveon.net</Link>,
            }}
          />
        </p>
        <p>
          <L id="terms.introduction.text3" defaultMessage="By accessing these websites we assume you accept these terms and conditions in full. Do not continue to use Hiveon's websites if you do not accept all of the terms and conditions stated on this page." />
        </p>
        <div>
          <L id="terms.introduction.text4" defaultMessage="The following terminology applies to these Terms and Conditions, Privacy Statement and any or all Agreements:" />
          <ul>
            <li><L id="terms.introduction.text5" defaultMessage="'Client', 'You' and 'Your' refers to you, the person accessing this website and accepting the Company's terms and conditions." /></li>
            <li><L id="terms.introduction.text6" defaultMessage="'The Company', 'Ourselves', 'We', 'Our' and 'Us', refers to our Company." /></li>
            <li><L id="terms.introduction.text7" defaultMessage="'Party', 'Parties', or 'Us', refers to both the Client and ourselves, or either the Client or ourselves." /></li>
            <li><L id="terms.introduction.text8" defaultMessage="Any use of the above terminology or other words in the singular, plural, capitalisation and/or he/she or they, are taken as interchangeable and therefore as referring to same." /></li>
          </ul>
        </div>
        <h2><L id="terms.cookies.title" defaultMessage="Cookies" /></h2>
        <p>
          <L
            id="terms.cookies.text1"
            defaultMessage="We employ the use of cookies. By using Hiveon's websites you consent to the use of cookies in accordance with Hiveon's {privacyLink}. Most of the modern day interactive websites use cookies to enable us to retrieve user details for each visit. Cookies are used in some areas of our site to enable the functionality of this area and ease of use for those people visiting."
            values={{
              privacyLink: <Link withLang to="/privacy" className={globalStyles.link}><L id="privacyPolicy" defaultMessage="Privacy Policy" /></Link>
            }}r
          />
        </p>
        <h2><L id="terms.modificationsToServices.title" defaultMessage="Modifications to Services" /></h2>
        <p>
          <L id="terms.modificationsToServices.text1" defaultMessage="Hiveon reserves the right at any time to modify or discontinue, temporarily or permanently, the Services (or any part thereof) with or without notice. You agree that Hiveon will not be liable to you or to any third party for any modification, suspension or discontinuance of the Services." />
        </p>
        <h2><L id="terms.links.title" defaultMessage="Links" /></h2>
        <p>
          <L id="terms.links.text1" defaultMessage="The Services may provide, or third parties may provide, links to other World Wide Web sites or resources. Because Hiveon has no control over such sites and resources, you acknowledge and agree that Hiveon is not responsible for the availability of such external sites or resources, and does not endorse and is not responsible or liable for any content, advertising, products, or other materials on or available from such sites or resources." />
          <L id="terms.links.text2" defaultMessage="You further acknowledge and agree that Hiveon is not responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such site or resource." />
        </p>
        <h2><L id="terms.disclaimers.title" defaultMessage="DISCLAIMER OF WARRANTIES, REPRESENTATIONS, AND CONDITIONS" /></h2>
        <p>
          <L id="terms.disclaimers.text1" defaultMessage="YOU EXPRESSLY UNDERSTAND AND AGREE THAT:" />
        </p>
        <ol start="1">
          <li><L id="terms.disclaimers.text2" defaultMessage="YOUR USE OF THESE SERVICES IS AT YOUR SOLE RISK. THE SERVICES ARE PROVIDED ON AN 'AS IS' AND 'AS AVAILABLE' BASIS. HIVEON EXPRESSLY DISCLAIMS ALL WARRANTIES, REPRESENTATIONS AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES, REPRESENTATIONS AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT." /></li>
          <li><L id="terms.disclaimers.text3" defaultMessage="HIVEON MAKES NO WARRANTY THAT:" />
            <br />
            <L id="terms.disclaimers.text4" defaultMessage="(i) THE SERVICES WILL MEET YOUR REQUIREMENTS," />
            <br /><L id="terms.disclaimers.text5" defaultMessage="(ii) THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE," />
            <br /><L id="terms.disclaimers.text6" defaultMessage="(iii) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES WILL BE ACCURATE OR RELIABLE," />
            <br /><L id="terms.disclaimers.text7" defaultMessage="(iv) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICES WILL MEET YOUR EXPECTATIONS, AND" />
            <br /><L id="terms.disclaimers.text8" defaultMessage="(v) ANY ERRORS IN THE SOFTWARE WILL BE CORRECTED." />
          </li>
          <li><L id="terms.disclaimers.text9" defaultMessage="ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICES IS DONE AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR HARDWARE AND COMPUTER SYSTEMS OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL." /></li>
          <li><L id="terms.disclaimers.text10" defaultMessage="NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM HIVEON OR THROUGH OR FROM THE SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TERMS." /></li>
        </ol>
        <h2><L id="terms.limitation.title" defaultMessage="LIMITATION OF LIABILITY" /></h2>
        <div>
          <L id="terms.limitation.text1" defaultMessage="YOU EXPRESSLY UNDERSTAND AND AGREE THAT HIVEON SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES (EVEN IF HIVEON HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM:" />
          <ul className={s.listStyleNone}>
            <li><L id="terms.limitation.text2" defaultMessage="(i) THE USE OR THE INABILITY TO USE THE SERVICES;" /></li>
            <li><L id="terms.limitation.text3" defaultMessage="(ii) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION, OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE SERVICES;" /></li>
            <li><L id="terms.limitation.text4" defaultMessage="(iii) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA;" /></li>
            <li><L id="terms.limitation.text5" defaultMessage="(iv) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICES; OR" /></li>
            <li><L id="terms.limitation.text6" defaultMessage="(v) ANY OTHER MATTER RELATING TO THE SERVICES." /></li>
          </ul>
        </div>
        <h2><L id="terms.exclusionsLimitations.title" defaultMessage="Exclusions and Limitations" /></h2>
        <p>
          <L id="terms.exclusionsLimitations.text1" defaultMessage="Some jurisdictions do not allow the exclusion of certain warranties, representations and conditions or the limitation or exclusion of liability for incidental or consequential damages. Accordingly, some of the above limitations may not apply to you." />
        </p>
        <h2><L id="terms.use.title" defaultMessage="Use of Services" /></h2>
        <p>
          <L id="terms.use.text1" defaultMessage="Harassment in any manner or form on the Services, including via e-mail, chat, or by use of obscene or abusive language, is strictly forbidden. Impersonation of others, including a Hiveon Team or other licensed employee, admin, or representative, as well as other members or visitors on the Services is prohibited. You may not upload to, distribute, or otherwise publish through the Services any content which is libelous, defamatory, obscene, threatening, invasive of privacy or publicity rights, abusive, illegal, or otherwise objectionable which may constitute or encourage a criminal offense, violate the rights of any party or which may otherwise give rise to liability or violate any law. You may not upload commercial content on the Services or use the Services to solicit others to join or become members of any other commercial online service or other organization." />
        </p>
        <p>
          <L id="terms.use.text2" defaultMessage="It is strictly forbidden to create multiple accounts for the purpose of using the system for free. You are allowed to have only one Hive OS account. In case you create multiple accounts, all of them will be detected and banned, and the mining process on all the farms of these accounts will be suspended. If you use system bugs that allow avoiding payment, your account will also be banned." />
        </p>
        <p>
          <L id="terms.use.text3" defaultMessage="According to the terms of use of the system, it is not possible to return to your personal wallet deposits, bonuses and funds that were deposited during the promotions and led to the receipt of bonuses." />
        </p>
        <p>
          <L id="terms.use.text4" defaultMessage="According to the terms of use of the system, it is not possible to return to your personal wallet deposits, bonuses and funds that were deposited during the promotions and led to the receipt of bonuses." />
        </p>
        <p>
          <L id="terms.use.text5" defaultMessage="According to the terms of use of the system, it is not possible to return to your personal wallet deposits, bonuses and funds that were deposited during the promotions and led to the receipt of bonuses." />
        </p>
        <h2><L id="terms.contact.title" defaultMessage="Contact Information" /></h2>
        <p>
          <L
            id="terms.contact.text1"
            defaultMessage="If you have any questions about this terms, please contact us: {email}"
            values={{
              email: <a href={`mailto:${WL.getSupportEmail()}`}>{WL.getSupportEmail()}</a>
            }}
          />
        </p>
      </div>
    );
  }
}


export default TermsContainer;
